import { PRODUCT_CODES } from '@/constants'

const accordionItems = {
    [`${PRODUCT_CODES.SAATVA_CLASSIC}`]: {
        certiPurContent: 'accordion-content-certipur',
    },
    [`${PRODUCT_CODES.MEMORY_FOAM_HYBRID}`]: {
        certiPurContent: 'accordion-content-certipur',
    },
    [`${PRODUCT_CODES.SOLAIRE}`]: {
        certiPurContent: 'accordion-content-certipur',
    },
    [`${PRODUCT_CODES.SAATVA_HD}`]: {
        certiPurContent: 'accordion-content-certipur',
    }
}

const config = {
    [`${PRODUCT_CODES.SAATVA_LATEX_HYBRID}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'latex-hybrid-layer-diagram',
        imgAlt: 'Latex Hybrid mattress diagram',
        title: `Pure, healthy sleep built into${'\u00A0'}every${'\u00A0'}layer`,
        selectedIconColor: 'bgAccentGreen',
        layers: [
            {
                title: 'Soft & breathable sleep surface',
                texts: [
                    '<span class="t-base">Hand-tufted</span> cushioned Euro top design for comfort and durability',
                    'Cover made with naturally breathable and hypoallergenic <span class="t-base">organic cotton</span> to promote cooler, healthier sleep',
                    `Treated with our exclusive <span class="t-base">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and${'\u00A0'}mildew`
                ],
                imageIconPositionStandard: { left: '5%', top: '-1%' },
                imageIconPositionTablet: { left: '25%', top: '-1%' }
            },
            {
                title: 'Extra layer of comfort & breathability',
                texts: [
                    '<span class="t-base">GOTS® certified organic New Zealand wool</span> naturally wicks away moisture',
                    'Regulates body temperature for extra breathability',
                    'Acts as a natural flame barrier, no fiberglass or toxic chemical flame retardants added',
                    'Naturally antimicrobial and resistant to dust mites'
                ],
                imageIconPositionStandard: { left: '15%', top: '11%' },
                imageIconPositionTablet: { left: '45%', top: '10%' }
            },
            {
                title: 'Zoned, pressure-free support with excellent airflow',
                texts: [
                    '3" layer of <span class="t-fontWeight--normal">5-zone natural latex</span> offers enhanced support in the lumbar region',
                    'Unmatched buoyancy and pressure-point relief',
                    'Vented airflow channels go through the entire latex layer for superb breathability and cooling',
                    `Naturally hypoallergenic, resistant to mold, mildew, and dust mites, and doesn't off-gas`
                ],
                imageIconPositionStandard: { left: '5%', top: '23%' },
                imageIconPositionTablet: { left: '35%', top: '24%' }
            },
            {
                title: 'Responsive feel & edge support',
                texts: [
                    `<span class="t-base">Individually wrapped coils</span> made of recycled steel respond to your body's movement and contour to your curves.`,
                    'Ergonomic firmness zones with enhanced support and durability in the lumbar region where most of your body weight is concentrated',
                    'Minimizes motion transfer',
                    'Maintains great airflow throughout the mattress'
                ],
                imageIconPositionStandard: { left: '15%', top: '39%' },
                imageIconPositionTablet: { left: '65%', top: '36%' }
            },
            {
                title: 'Stable & durable base',
                texts: [
                    '<span class="t-base">Organic eco loft pad</span> made of organic cotton and GOTS® certified organic wool provides a stable base to anchor the hand-tufting',
                    'Prevents the layers from shifting, resulting in a longer-lasting mattress'
                ],
                imageIconPositionStandard: { left: '5%', top: '54%' },
                imageIconPositionTablet: { left: '24%', top: '57%' }
            }
        ]
    },
    [`${PRODUCT_CODES.SAATVA_CLASSIC}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'classic-layer-diagram',
        imgAlt: 'Classic mattress diagram',
        title: 'More nights of restorative sleep built into every layer',
        selectedIconColor: 'bgContrast2',
        layers: [
            {
                title: 'Breathable pillow top sleep surface',
                texts: [
                    'Contouring <span class="t-fontWeight--normal">3" Euro pillow top with Lumbar Zone® Quilting</span> to give you more support in the center third of the mattress where you need it most',
                    'Cover made with naturally breathable and hypoallergenic <span class="t-fontWeight--normal">organic cotton</span> to promote cooler, healthier sleep',
                    'Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew',
                    'No fiberglass or toxic chemical flame retardants, instead we use a<span class="t-fontWeight--normal"> natural, plant-based thistle pulp flame barrier</span>'
                ],
                imageIconPositionStandard: { left: '13%', top: '-1%' },
                imageIconPositionTablet: { left: '15%', top: '0%' }
            },
            {
                title: 'Chiropractor-approved back support',
                hasScrollLink: true,
                dynamicTexts: [
                    {
                        receiveParams: true,
                        text (redirectFunc) {
                            return (
                                <>
                                    Unique {redirectFunc({ copy: 'CertiPUR-US®', accordionContent: accordionItems[PRODUCT_CODES.SAATVA_CLASSIC].certiPurContent })} certified <span className="t-fontWeight--normal">1/2" gel-infused memory foam lumbar crown</span> offers excellent pressure-point relief while actively drawing heat away from the sleep surface
                                </>
                            )
                        }
                    },
                    {
                        text: `<span class="t-fontWeight--normal">Patented Lumbar Zone® Active Spinal Wire</span> helps to keep your spine in healthy alignment`
                    },
                    {
                        text: `Works with the patented Lumbar Zone® Quilting above to give you extra support and durability in the middle of the mattress where you need it most`
                    }
                ],
                imageIconPositionStandard: { left: '28%', top: '9%' },
                imageIconPositionTablet: { left: '30%', top: '9%' }
            },
            {
                title: 'Responsive, body-cradling comfort',
                texts: [
                    '884 <span class="t-fontWeight--normal">Lumbar Zone® individually wrapped coils (in queen)</span> made from durable 14.5-gauge recycled steel',
                    'Stronger & firmer in the center third of the mattress where most of your body weight is concentrated ',
                    'Contour to your curves to relieve pressure points for better circulation',
                    `Respond to your body's movements, reducing motion transfer for more undisturbed rest`
                ],
                imageIconPositionStandard: { left: '20%', top: '24%' },
                imageIconPositionTablet: { left: '19%', top: '22%' }
            },
            {
                title: 'Sturdy edge support',
                texts: [
                    `<span class="t-fontWeight--normal">High-density foam rails</span> create stable, firmer edge${'\u00A0'}support`,
                    `Gives you more sleep surface and makes getting in and out of bed${'\u00A0'}easier`
                ],
                imageIconPositionStandard: { left: '13%', top: '39%' },
                imageIconPositionTablet: { left: '17%', top: '40%' }
            },
            {
                title: 'The most durable support base',
                texts: [
                    `Dual-coil design has a <span class="t-fontWeight--normal">base coil unit</span> made of 416 (in queen) 13-gauge tempered steel for maximum durability to prevent sagging over${'\u00A0'}time`,
                    `Allows better airflow for cooler, more comfortable${'\u00A0'}sleep`
                ],
                imageIconPositionStandard: { left: '47%', top: '46%' },
                imageIconPositionTablet: { left: '46%', top: '46%' }
            }
        ]
    },
    [`${PRODUCT_CODES.SOLAIRE}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'solaire-layer-diagram',
        imgAlt: 'Solaire mattress diagram',
        title: 'Life-changing sleep built into every game-changing layer',
        selectedIconColor: 'bgAccentBlue',
        layers: [
            {
                title: 'Breathable cushioned pillow top sleep surface',
                texts: [
                    'Contouring <span class="t-fontWeight--normal">3" Euro pillow top with Lumbar Zone® Quilting</span> to give you more support in the center third of the mattress where you need it most',
                    `Cover made with naturally breathable and hypoallergenic <span class="t-fontWeight--normal">organic cotton</span> to promote cooler, healthier${'\u00A0'}sleep`,
                    `Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and${'\u00A0'}mildew`,
                    'No fiberglass or toxic chemical flame retardants, instead we use a <span class="t-fontWeight--normal">Paladin flame barrier</span> made of a poly & rayon blend'
                ],
                imageIconPositionStandard: { left: '28%', top: '12%' },
                imageIconPositionTablet: { left: '28%', top: '13%' }
            },
            {
                title: 'Buoyant, pressure-free support',
                texts: [
                    `1" layer of ergonomic <span class="t-fontWeight--normal">5-zone natural latex</span> for enhanced lumbar support and pressure-point${'\u00A0'}relief`,
                    `Naturally hypoallergenic, resistant to mold, mildew, and dust mites, and doesn't${'\u00A0'}off-gas`
                ],
                imageIconPositionStandard: { left: '40%', top: '24%' },
                imageIconPositionTablet: { left: '46%', top: '26%' }
            },
            {
                title: 'Cooling, contouring support',
                hasScrollLink: true,
                dynamicTexts: [
                    {
                        receiveParams: true,
                        text (redirectFunc) {
                            return (
                                <>
                                    <span className="t-fontWeight--normal">{redirectFunc({ copy: 'CertiPUR-US®', accordionContent: accordionItems[PRODUCT_CODES.SOLAIRE].certiPurContent })} certified 2" gel-infused memory foam</span> helps disperse body heat away for cooler{'\u00A0'}sleep
                                </>
                            )
                        }
                    },
                    {
                        text: `Contours to your body for pressure-point${'\u00A0'}relief`
                    }
                ],
                imageIconPositionStandard: { left: '20%', top: '30%' },
                imageIconPositionTablet: { left: '34%', top: '32%' }
            },
            {
                title: 'Moisture barrier for a longer-lasting mattress',
                texts: [
                    `<span class="t-fontWeight--normal">Moisture barrier layers</span> protect internal mechanical components to prevent bacterial${'\u00A0'}growth`
                ],
                imageIconPositionStandard: { left: '33%', top: '37%' },
                imageIconPositionTablet: { left: '58%', top: '39%' }
            },
            {
                title: 'Consistent, edge-to-edge comfort',
                texts: [
                    `No-sink <span class="t-fontWeight--normal">vulcanized air chambers</span> with multi-directional box baffle${'\u00A0'}design`,
                    `Maintains even firmness throughout the mattress to evenly support you from head to${'\u00A0'}toe`
                ],
                imageIconPositionStandard: { left: '60%', top: '45%' },
                imageIconPositionTablet: { left: '27%', top: '45%' }
            },
            {
                title: 'Quiet, clutter-free performance',
                texts: [
                    `<span class="t-fontWeight--normal">Reinforced power edge enclosure</span> provides a stable${'\u00A0'}base`,
                    `Houses the quiet air inflator and hoses out of sight and out of your${'\u00A0'}way`
                ],
                imageIconPositionStandard: { left: '34%', top: '59%' },
                imageIconPositionTablet: { left: '43%', top: '60%' }
            }
        ]
    },
    [`${PRODUCT_CODES.MEMORY_FOAM_HYBRID}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'memory-foam-hybrid-diagram',
        imgAlt: 'Memory Foam Hybrid mattress diagram',
        title: 'Balanced support & cooling comfort built into every layer',
        selectedIconColor: 'bgContrast2',
        layers: [
            {
                title: `Supportive & breathable sleep surface`,
                texts: [
                    'Cover made with naturally breathable and hypoallergenic <span class="t-fontWeight--normal">organic cotton</span> to promote cooler, healthier sleep',
                    'Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew',
                    '<span class="t-fontWeight--normal">Patented Lumbar Zone® Quilting</span> gives you more support in the center third of the mattress to ensure healthy spinal alignment',
                    'No fiberglass or toxic chemical flame retardants, instead we use a<span class="t-fontWeight--normal"> flame barrier made of natural plant-based thistle pulp.</span>',
                    'Quilted with comfort foams and a nontoxic natural thistle & wool blend flame barrier'
                ],
                imageIconPositionStandard: { left: '11%', top: '7%' },
                imageIconPositionTablet: { left: '18%', top: '8%' }
            },
            {
                title: 'Highly breathable, contouring comfort layer',
                texts: [
                    '<span class="t-fontWeight--normal">1 1/2” AirCradle™ memory foam</span> has a unique wave design to help circulate internal airflow through “air pockets” under the foam',
                    'Gives the mattress its deep, body-contouring feel',
                    'Premium 4-lb memory foam offers pressure-relieving support and long-lasting durability'
                ],
                imageIconPositionStandard: { left: '27%', top: '20%' },
                imageIconPositionTablet: { left: '33%', top: '19%' }
            },
            {
                title: 'Pressure-relieving lower back support',
                hasScrollLink: true,
                dynamicTexts: [
                    {
                        receiveParams: true,
                        text (redirectFunc) {
                            return (
                                <>
                                    Unique {redirectFunc({ copy: 'CertiPUR-US®', accordionContent: accordionItems[PRODUCT_CODES.MEMORY_FOAM_HYBRID].certiPurContent })} certified <span className="t-fontWeight--normal">1/2" gel-infused memory foam lumbar crown</span> offers excellent pressure-point relief while actively drawing heat away from the sleep surface
                                </>
                            )
                        }
                    },
                    {
                        text: `Works with the patented Lumbar Zone® Quilting above to give you extra support and durability in the middle of the mattress where you need it most`
                    },
                    {
                        text: 'Virtually eliminates body impressions from forming over time'
                    }
                ],
                imageIconPositionStandard: { left: '67%', top: '31%' },
                imageIconPositionTablet: { left: '65%', top: '30%' }
            },
            {
                title: 'Responsive feel, great airflow & motion isolation',
                texts: [
                    '<span class="t-fontWeight--normal">8” individually wrapped comfort coils </span>',
                    'Dynamically respond to your body’s movements to reduce motion transfer',
                    'Allow for great airflow to keep you comfortably cool all night',
                    'Helps prevent body impressions from forming over time'
                ],
                imageIconPositionStandard: { left: '44%', top: '41%' },
                imageIconPositionTablet: { left: '45%', top: '40%' }
            },
            {
                title: 'No sagging edges',
                texts: [
                    '<span class="t-fontWeight--normal">High-density foam rails</span> create stable, firmer edge support',
                    'Gives you more sleep surface and makes getting in and out of bed easier'
                ],
                imageIconPositionStandard: { left: '29%', top: '42%' },
                imageIconPositionTablet: { left: '30%', top: '41%' }
            },
            {
                title: 'Sturdy base support',
                texts: [
                    '<span class="t-fontWeight--normal">1” premium support core foam</span> creates a highly resilient and durable base',
                    'Ensures maximum durability and stability for years to come'
                ],
                imageIconPositionStandard: { left: '50%', top: '54%' },
                imageIconPositionTablet: { left: '51%', top: '55%' }
            }
        ]
    },
    [`${PRODUCT_CODES.SAATVA_RX}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'saatva-rx-diagram',
        imgAlt: 'Saatva Rx mattress diagram',
        title: 'Maximum relief & restorative sleep built into every layer',
        selectedIconColor: 'bgActionBase',
        layers: [
            {
                title: `Supportive & breathable sleep surface`,
                texts: [
                    'Cover made with naturally breathable and hypoallergenic <span class="t-fontWeight--normal">organic cotton</span> to promote cooler, healthier sleep',
                    'Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew',
                    '<span class="t-fontWeight--normal">Proprietary Lumbar Zone® Quilting</span> gives you more support in the center third of the mattress to ensure healthy spinal alignment',
                    'No fiberglass or toxic chemical flame retardants, instead we use a<span class="t-fontWeight--normal"> flame barrier made of natural plant-based thistle pulp.</span>'
                ],
                imageIconPositionStandard: { left: '27%', top: '7%' },
                imageIconPositionTablet: { left: '25%', top: '5%' }
            },
            {
                title: 'Pressure-relieving lower back support',
                texts: [
                    '<span class="t-fontWeight--normal">Unique 1/2" gel-infused memory foam lumbar crown</span> actively draws heat away from the sleep surface',
                    'Works with the patented Lumbar Zone® Quilting above to give you extra support and durability in the middle of the mattress where you need it most',
                    'Virtually eliminates body impressions from forming over time'
                ],
                imageIconPositionStandard: { left: '59%', top: '17%' },
                imageIconPositionTablet: { left: '62%', top: '16%' }
            },
            {
                title: 'Plush, cradling comfort',
                texts: [
                    '<span class="t-fontWeight--normal">2 ¾" high-density foam layers</span> encase the micro-coil comfort layer',
                    'Cradles the body in comfort while providing optimal support for the entire body'
                ],
                imageIconPositionStandard: { left: '37%', top: '22%' },
                imageIconPositionTablet: { left: '37%', top: '22%' }
            },
            {
                title: 'Body-contouring pressure relief',
                texts: [
                    'Innovative <span class="t-fontWeight--normal">1" micro-coil layer</span> consists of 1,628 micro-coils to offer more flexibility than any other coil type',
                    'Provides excellent body contouring to relieve pressure points',
                    'Stronger micro-coils in the lumbar zone and perimeter for enhanced back support and durability'
                ],
                imageIconPositionStandard: { left: '18%', top: '25%' },
                imageIconPositionTablet: { left: '19%', top: '25%' }
            },
            {
                title: 'Cooling pressure relief',
                texts: [
                    '<span class="t-fontWeight--normal">Therapeutic Support Core™</span> features our proprietary <span class="t-fontWeight--normal">Rx Wave-Tech® Foam Layer</span>',
                    'Engineered to deliver a unique blend of responsiveness like natural latex foam and body-contouring comfort like memory foam for a transformative sleep experience',
                    'Proprietary <span class="t-fontWeight--normal">Wave-Tech® foam design with a unique 5-zone surface</span> meticulously designed to relieve pressure points in key areas such as hips, shoulders, and lumbar region',
                    'Infused with graphite and phase-change material (PCM) to give optimal temperature regulation for cooler, more restorative sleep'
                ],
                imageIconPositionStandard: { left: '44%', top: '35%' },
                imageIconPositionTablet: { left: '44%', top: '36%' }
            },
            {
                title: 'Responsive feel & great edge support',
                texts: [
                    '<span class="t-fontWeight--normal">8” individually wrapped comfort coil unit</span> are made with triple-tempered 15-gauge recycled steel for durability',
                    'The 792 coils (in queen) provide a responsive feel, excellent motion isolation, and superb pressure relief by automatically adjusting to your every curve and movement',
                    'Features <span class="t-fontWeight--normal">firmer caliper spring coils</span> along the left and right rails to give the mattress great edge support'
                ],
                imageIconPositionStandard: { left: '65%', top: '39%' },
                imageIconPositionTablet: { left: '70%', top: '38%' }
            },
            {
                title: 'Stable base',
                texts: [
                    '<span class="t-fontWeight--normal">High density base support foam</span> is applied below the comfort coil unit to stabilize the individual coils'
                ],
                imageIconPositionStandard: { left: '48%', top: '52%' },
                imageIconPositionTablet: { left: '54%', top: '53%' }
            }
        ]
    },
    [`${PRODUCT_CODES.LOOM_AND_LEAF}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'loom-mattress-diagram',
        imgAlt: 'Loom and Leaf mattress diagram',
        title: 'Body-hugging comfort & pressure relief built into every layer',
        selectedIconColor: 'bgContrast2',
        layers: [
            {
                title: `Cushioned & breathable sleep surface`,
                texts: [
                    'Cover made with naturally breathable and hypoallergenic <span class="t-fontWeight--normal">organic cotton</span> to promote cooler, healthier sleep',
                    '<span class="t-fontWeight--normal">Lumbar Zone® Quilting</span>, with two layers of plush comfort foams and nontoxic natural thistle flame barrier, gives you more support in the center third of the mattress where you need it most',
                    'Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew',
                    'No fiberglass or toxic chemical flame retardants, instead we use a<span class="t-fontWeight--normal"> natural, plant-based thistle pulp flame barrier</span>'
                ],
                imageIconPositionStandard: { left: '14%', top: '13%' },
                imageIconPositionTablet: { left: '14%', top: '7%' }
            },
            {
                title: 'Pressure-relieving back support',
                texts: [
                    'Proprietary <span class="t-fontWeight--normal">gel-infused memory foam lumbar crown</span> helps keep you cooler',
                    'Provides proper spinal alignment in any sleep position',
                    'Improves circulation by relieving pressure points',
                    'Enhanced lower back support and durability in the middle of the mattress, where you need it most.'
                ],
                imageIconPositionStandard: { left: '26%', top: '18%' },
                imageIconPositionTablet: { left: '30%', top: '13%' }
            },
            {
                title: 'Cooling & breathable foam layer',
                texts: [
                    '<span class="t-fontWeight--normal">2" CertiPUR-US® certified gel-infused memory foam</span> with micro perforations for enhanced breathability',
                    'Molds and contours to your body\'s curves for excellent pressure relief'
                ],
                imageIconPositionStandard: { left: '19%', top: '26%' },
                imageIconPositionTablet: { left: '21%', top: '20%' }
            },
            {
                title: 'Body-hugging high-density memory foam core',
                texts: [
                    '2.5" <span class="t-fontWeight--normal">CertiPUR-US® certified ultra premium 5-lb memory foam</span>',
                    'Provides the perfect balance of comfort and support',
                    'Gives the mattress its deep body-hugging feel'
                ],
                imageIconPositionStandard: { left: '28%', top: '33%' },
                imageIconPositionTablet: { left: '32%', top: '29%' }
            },
            {
                title: 'Transitional foam layer',
                texts: [
                    '2" <span class="t-fontWeight--normal">CertiPUR-US® certified transitional loft pad</span> determines the firmness level of the mattress (2 firmness options)',
                    'Transitions pressure between the comfort layers above and the denser support foam layer below'
                ],
                imageIconPositionStandard: { left: '37%', top: '40%' },
                imageIconPositionTablet: { left: '42%', top: '38%' }
            },
            {
                title: 'Durable base support',
                texts: [
                    '5.5" <span class="t-fontWeight--normal">CertiPUR-US® certified foam base</span> supports the upper layers',
                    'Ensures maximum durability and stability for years of restorative sleep'
                ],
                imageIconPositionStandard: { left: '46%', top: '50%' },
                imageIconPositionTablet: { left: '49%', top: '48%' }
            }
        ]
    },
    [`${PRODUCT_CODES.SAATVA_CONTOUR5}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'contour5-layer-diagram',
        imgAlt: 'Contour5 mattress diagram',
        title: 'Luxe feel & cooling comfort built into every layer',
        selectedIconColor: 'bgAccentBlue',
        layers: [
            {
                title: `Breathable smooth knit cover`,
                texts: [
                    'Made with naturally breathable and hypoallergenic <span class="t-fontWeight--normal">organic cotton</span> to promote cooler, healthier sleep',
                    'Streamlined waterfall knit cover is expertly tailored with flanged and tape-edge construction for improved layer anchoring and durability',
                    'Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew'
                ],
                imageIconPositionStandard: { left: '30%', top: '3%' },
                imageIconPositionTablet: { left: '30%', top: '3%' }
            },
            {
                title: 'Fully covered with a nontoxic flame barrier',
                hasScrollLink: true,
                dynamicTexts: [
                    {
                        receiveParams: true,
                        text (scrollToSection) {
                            return (
                                <>
                                    {scrollToSection('GREENGUARD® Gold certified', 'accordion-content-greenguard', 'accordion-title-greenguard')}Flame barrier fully surrounds all six sides of the mattress, directly below the knit cover
                                </>
                            )
                        }
                    },
                    {
                        text: `Made with nontoxic cellulose fibers derived from tree byproducts—NO fiberglass or toxic chemical flame retardants`
                    },
                    {
                        text: `Screened for more than 10,000 chemicals and VOCs (volatile organic compounds) for cleaner indoor air`
                    }
                ],
                imageIconPositionStandard: { left: '18%', top: '13%' },
                imageIconPositionTablet: { left: '20%', top: '15%' }
            },
            {
                title: 'Next-generation cooling technology & lumbar support',
                texts: [
                    '<span class="t-fontWeight--normal">CoolVent™ System with our Lumbar Zone® PCM Matrix,</span> a phase change material that absorbs and dissipates excess body heat to keep you comfortable all night long',
                    'Helps keep the sleep surface cooler while regulating the temperature at the center third of the mattress',
                    'Lumbar region of the mattress stays several degrees cooler to allow optimal support where you need it most (cooler foam = more support)'
                ],
                imageIconPositionStandard: { left: '40%', top: '18%' },
                imageIconPositionTablet: { left: '40%', top: '19%' }
            },
            {
                title: ' Body contouring with optimal airflow',
                texts: [
                    '1.5” gel-infused high-density 4lb memory foam provides body-contouring support, pressure relief, and cooling comfort',
                    'Airflow channels throughout the memory foam layer means it never sleeps hot'
                ],
                imageIconPositionStandard: { left: '12%', top: '21%' },
                imageIconPositionTablet: { left: '14%', top: '21%' }
            },
            {
                title: 'Luxurious, body-hugging core',
                texts: [
                    '3” ultra-premium 5lb memory foam makes up the heart of the Contour5',
                    'Gives the mattress its luxe body-cradling feel and superb pressure relief',
                    'High-density foam ensures durability and resists body impressions from forming over time'
                ],
                imageIconPositionStandard: { left: '35%', top: '33%' },
                imageIconPositionTablet: { left: '35%', top: '33%' }
            },
            {
                title: 'Transition layer available in two firmness options',
                texts: [
                    '2” loft layer transitions pressure between the comfort layers and the denser support foam base below',
                    'Choose from two firmness options: Medium and Firm'
                ],
                imageIconPositionStandard: { left: '20%', top: '41%' },
                imageIconPositionTablet: { left: '20%', top: '43%' }
            },
            {
                title: 'Highly durable support foam base',
                texts: [
                    '6” high-density foam supports the top layers of the mattress',
                    'Provides optimal edge support to make getting in and out of bed easier',
                    'Ensures maximum stability and durability for many years of restful sleep'
                ],
                imageIconPositionStandard: { left: '44%', top: '57%' },
                imageIconPositionTablet: { left: '44%', top: '57%' }
            }
        ]
    },
    [`${PRODUCT_CODES.ZENHAVEN}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'zen-mattress-diagram',
        imgAlt: 'Zenhaven mattress diagram',
        title: 'Pure, buoyant, pressure-free comfort built into every layer',
        selectedIconColor: 'bgContrast2',
        footerDescription: 'Zenhaven is a dual-sided, flippable mattress with a Luxury Plush side and a Gentle Firm side.',
        layers: [
            {
                title: `Supportive & breathable sleep surface`,
                texts: [
                    'Quilted cover made with naturally breathable and hypoallergenic <span class="t-fontWeight--normal">organic cotton</span> to promote cooler, healthier sleep',
                    'Proprietary <span class="t-fontWeight--normal">Lumbar Zone® Quilting</span> gives you more support in the center third of the mattress where you need it most',
                    'Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew'
                ],
                imageIconPositionStandard: { left: '18%', top: '4%' },
                imageIconPositionTablet: { left: '18%', top: '7%' }
            },
            {
                title: `Extra layer of comfort & breathability`,
                texts: [
                    '<span class="t-fontWeight--normal">GOTS® certified organic New Zealand wool</span> naturally wicks away moisture',
                    'Regulates body temperature for extra breathability',
                    'Acts as a natural flame barrier, no fiberglass or toxic chemical flame retardants added',
                    'Naturally antimicrobial and resistant to dust mites'
                ],
                imageIconPositionStandard: { left: '26%', top: '14%' },
                imageIconPositionTablet: { left: '26%', top: '16%' }
            },
            {
                title: `Zoned, pressure-free support with excellent airflow`,
                texts: [
                    'Unique 5-zone natural latex offers enhanced support in the lumbar region',
                    'Unmatched buoyancy and pressure-point relief',
                    '<span class="t-fontWeight--normal">Vented airflow channels</span> go through the entire latex layer for superb breathability and cooling',
                    'Determines the firmness level on either side of the two-sided mattress',
                    `Naturally hypoallergenic, resistant to mold, mildew, and dust mites, and doesn't off-gas`
                ],
                imageIconPositionStandard: { left: '37%', top: '23%' },
                imageIconPositionTablet: { left: '37%', top: '25%' }
            },
            {
                title: `Highly durable latex support cores`,
                texts: [
                    '<span class="t-fontWeight--normal">Natural latex core</span> layers give optimal support and durability',
                    'No petroleum-based foams added'
                ],
                imageIconPositionStandard: { left: '48%', top: '37%' },
                imageIconPositionTablet: { left: '48%', top: '39%' }
            },
            {
                visibleText: false,
                btnTitle: 3,
                imageIconPositionStandard: { left: '37%', top: '48%' },
                imageIconPositionTablet: { left: '37%', top: '49%' }
            },
            {
                visibleText: false,
                btnTitle: 2,
                imageIconPositionStandard: { left: '25%', top: '53%' },
                imageIconPositionTablet: { left: '25%', top: '54%' }
            }
        ]
    },
    [`${PRODUCT_CODES.SAATVA_HD}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'hd-mattress-diagram',
        imgAlt: 'Saatva HD mattress diagram',
        title: 'Luxurious comfort & heavy-duty support built into every layer',
        selectedIconColor: 'bgContrast2',
        layers: [
            {
                title: `Breathable cushioned pillow top sleep surface`,
                texts: [
                    '<span class="t-fontWeight--normal">Hand-tufted for premium durability</span>, helps to prevent body impressions over time',
                    '<span class="t-fontWeight--normal">3" Euro pillow top</span> is quilted with a combination of plush fibers & comfort foams',
                    'Cover made with naturally breathable and hypoallergenic organic cotton to promote cooler, healthier sleep',
                    'Treated with our exclusive <span class="t-fontWeight--normal">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew',
                    'No fiberglass or toxic chemical flame retardants, instead we use a<span class="t-fontWeight--normal"> natural, plant-based thistle pulp flame barrier</span>'
                ],
                imageIconPositionStandard: { left: '12%', top: '5%' },
                imageIconPositionTablet: { left: '16%', top: '5%' }
            },
            {
                title: `Durable, cooling, pressure-relieving support`,
                texts: [
                    '1" layer of <span class="t-fontWeight--normal">5-zone natural latex</span> provides enhanced support in the lumbar region',
                    'Unmatched pressure-point relief for the hips and shoulders',
                    `Naturally hypoallergenic, resistant to mold, mildew, and dust mites, and doesn't off-gas`
                ],
                imageIconPositionStandard: { left: '36%', top: '13%' },
                imageIconPositionTablet: { left: '27%', top: '13%' }
            },
            {
                title: `Luxurious, contouring comfort`,
                hasScrollLink: true,
                dynamicTexts: [
                    {
                        receiveParams: true,
                        text (redirectFunc) {
                            return (
                                <>
                                1.5" <span className="t-fontWeight--normal">{redirectFunc({ copy: 'CertiPUR-US®', accordionContent: accordionItems[PRODUCT_CODES.SAATVA_HD].certiPurContent })} certified 5-lb memory foam</span>
                                </>
                            )
                        }
                    },
                    {
                        text: `Gives the mattress that deep body-hugging feel`
                    },
                    {
                        text: `Improves circulation by relieving pressure points`
                    }
                ],
                imageIconPositionStandard: { left: '24%', top: '22%' },
                imageIconPositionTablet: { left: '18%', top: '20%' }
            },
            {
                title: 'Denser comfort layer',
                texts: [
                    '<span class="t-fontWeight--normal">2" comfort foam</span> adds durable support to the 5-lb memory foam'
                ],
                imageIconPositionStandard: { left: '12%', top: '30%' },
                imageIconPositionTablet: { left: '14%', top: '30%' }
            },
            {
                title: 'No sagging edges',
                texts: [
                    'Double the edge support of other luxury mattresses with stable & firm 6" high-density foam rails',
                    'Gives you more sleep surface and makes it easier to get in and out of bed',
                    'Contains a natural thistle flame barrier that encases the entire mattress'
                ],
                imageIconPositionStandard: { left: '29%', top: '46%' },
                imageIconPositionTablet: { left: '26%', top: '45%' }
            },
            {
                title: 'Responsive coil unit',
                texts: [
                    '<span class="t-fontWeight--normal">14-gauge tempered recycled steel coils</span> are 25% stronger than industry standard',
                    '<span class="t-fontWeight--normal">Lumbar Zone® Active Spinal Wire</span> offers enhanced lower back support',
                    '<span class="t-fontWeight--normal">Individually wrapped coils</span> are designed to eliminate motion transfer'
                ],
                imageIconPositionStandard: { left: '48%', top: '48%' },
                imageIconPositionTablet: { left: '44%', top: '48%' }
            },
            {
                title: 'Durable base support',
                texts: [
                    '<span class="t-fontWeight--normal">2" high-density hard foam</span> provides more stable, durable support for heavier bodies'
                ],
                imageIconPositionStandard: { left: '69%', top: '51%' },
                imageIconPositionTablet: { left: '66%', top: '52%' }
            }
        ]
    },
    [`${PRODUCT_CODES.SAATVA_YOUTH}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'youth-layer-diagram',
        imgAlt: 'Saatva Youth mattress diagram',
        title: `Adaptive support and comfort that grows with your little one`,
        footerDescription: 'Saatva Youth is a dual-sided, flippable mattress with an Ages 3-7 side and an Ages 8-12 side.',
        selectedIconColor: 'bgContrast2',
        layers: [
            {
                title: 'Soft, breathable, water-resistant cover',
                texts: [
                    'Made with naturally breathable <span class="t-base">organic cotton</span> with a water resistant finish and treated with <span class="t-base">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew',
                    '<span class="t-base">Lumbar Zone® Quilting</span> for added back support',
                    'Quilted with comfort foams and a nontoxic natural thistle & wool blend flame barrier'
                ],
                imageIconPositionStandard: { left: '19%', top: '27%' },
                imageIconPositionTablet: { left: '20%', top: '23%' }
            },
            {
                title: 'Optimal spinal support for kids ages 3-7',
                texts: [
                    '<span class="t-base">5-zone support foam layer</span> is firmer around the center third of the mattress',
                    'Maintains proper spinal alignment in any sleep position'
                ],
                imageIconPositionStandard: { left: '7%', top: '37%' },
                imageIconPositionTablet: { left: '7%', top: '36%' }
            },
            {
                title: 'Strong yet flexible coils',
                texts: [
                    'Dual-sided coil unit made with durable 13.75-gauge recycled steel',
                    'Flexible enough to conform to growing bodies',
                    'Provides responsive support for combination sleepers'
                ],
                imageIconPositionStandard: { left: '22%', top: '55%' },
                imageIconPositionTablet: { left: '22%', top: '55%' }
            },
            {
                title: 'Enhanced lower back support for kids ages 8-12',
                texts: [
                    'Patented <span class="t-base">Lumbar Zone® Active Spinal Wire</span> helps maintain proper spinal alignment for older kids',
                    'Added support and durability in the center third of the mattress where most of your child’s body weight is concentrated'
                ],
                imageIconPositionStandard: { left: '54%', top: '43%' },
                imageIconPositionTablet: { left: '54%', top: '43%' }
            },
            {
                title: 'Supportive foam layer for kids ages 8-12',
                texts: [
                    '<span class="t-base">High-density comfort foam</span> provides body-contouring support',
                    'Wave design helps circulate internal airflow for breathability'
                ],
                imageIconPositionStandard: { left: '39%', top: '62%' },
                imageIconPositionTablet: { left: '39%', top: '64%' }
            },
            {
                visibleText: false,
                btnTitle: 1,
                imageIconPositionStandard: { left: '19%', top: '82%' },
                imageIconPositionTablet: { left: '19%', top: '88%' }
            }
        ]
    },
    [`${PRODUCT_CODES.CRIB_MATTRESS}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'crib-layer-diagram',
        imgAlt: 'Saatva Crib mattress diagram',
        title: `Dual-sided with comfort and safety built into every layer`,
        footerDescription: 'Saatva Crib mattress is a dual-sided, flippable mattress with a Firm and an Extra-Firm side.',
        selectedIconColor: 'bgAccentBlue',
        layers: [
            {
                title: 'Extra firm support for babies',
                texts: [
                    '<span class="t-base">Sturdy support layers</span> made with breathable eucalyptus fibers',
                    'Extra firm support is integral at the early stages of an infant’s growth to help prevent SIDS and other sleep-related accidents'
                ],
                imageIconPositionStandard: { left: '26%', top: '18%' },
                imageIconPositionTablet: { left: '26%', top: '18%' }
            },
            {
                title: 'Durable center coil unit',
                texts: [
                    'Made up of 200 <span class="t-base">recycled steel coils</span> for responsive support',
                    '<span class="t-base">Center zone technology</span> keeps your child centered and safely away from the edges of the crib'
                ],
                imageIconPositionStandard: { left: '36%', top: '38%' },
                imageIconPositionTablet: { left: '40%', top: '38%' }
            },
            {
                title: 'Reinforced double edge support',
                texts: [
                    '<span class="t-base">High-density natural wool rails</span> create stable, firmer edge support'
                ],
                imageIconPositionStandard: { left: '7%', top: '40%' },
                imageIconPositionTablet: { left: '10%', top: '38%' }
            },
            {
                title: 'Firm support for toddlers',
                texts: [
                    '<span class="t-base">Sturdy support layer</span> made with breathable eucalyptus fibers',
                    'Less firm than baby side but gives optimal support for fidgety toddlers'
                ],
                imageIconPositionStandard: { left: '22%', top: '54%' },
                imageIconPositionTablet: { left: '22%', top: '54%' }
            },
            {
                title: 'Pressure-free support',
                texts: [
                    '<span class="t-base">Natural latex layer</span> for a buoyant feel and durable support',
                    'Vented airflow channels go through the entire latex layer for superb breathability and cooling',
                    'Naturally hypoallergenic and resistant to mold, mildew, and dust mites'
                ],
                imageIconPositionStandard: { left: '55%', top: '60%' },
                imageIconPositionTablet: { left: '55%', top: '62%' }
            },
            {
                title: 'Soft, breathable cushioning',
                texts: [
                    '<span class="t-base">GOTS® certified organic New Zealand wool</span> naturally wicks away moisture as it helps regulate body temperature',
                    'Naturally antimicrobial and resistant to dust mites'
                ],
                imageIconPositionStandard: { left: '30%', top: '85%' },
                imageIconPositionTablet: { left: '30%', top: '89%' }
            }
        ]
    },
    [`${PRODUCT_CODES.BUNK_TRUNDLE_YOUTH}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'bunk-trundle-youth-layer-diagram',
        imgAlt: 'Bunk & Trundle mattress diagram',
        title: `All the comfort & support of a luxury mattress at just 6” high`,
        selectedIconColor: 'bgContrast2',
        layers: [
            {
                title: 'Soft, breathable, water-resistant cover',
                texts: [
                    'Made with naturally breathable <span class="t-base">organic cotton</span> with a water resistant finish and treated with <span class="t-base">Guardin™ botanical antimicrobial treatment</span> to inhibit the growth of bacteria, mold, and mildew',
                    '<span class="t-base">Lumbar Zone® Quilting</span> for added back support',
                    'No fiberglass or toxic chemical flame retardants, instead we use a<span class="t-fontWeight--normal"> flame barrier made of natural plant-based thistle pulp.</span>'
                ],
                imageIconPositionStandard: { left: '20%', top: '22%' },
                imageIconPositionTablet: { left: '25%', top: '21%' }
            },
            {
                title: 'Pressure-relieving lower back support',
                texts: [
                    '<span class="t-base">1/2” gel-infused memory foam lumbar crown</span> works with the Lumbar Zone® Quilting above to give you extra support and durability',
                    'Virtually eliminates body impressions from forming over time'
                ],
                imageIconPositionStandard: { left: '50%', top: '31%' },
                imageIconPositionTablet: { left: '51%', top: '31%' }
            },
            {
                title: 'Supportive transition layer',
                texts: [
                    '<span class="t-base">1” polyfoam comfort layer</span> between the sleep surface and coil unit below',
                    'Perfect balance of comfort and support'
                ],
                imageIconPositionStandard: { left: '30%', top: '37%' },
                imageIconPositionTablet: { left: '33%', top: '38%' }
            },
            {
                title: 'Added insulation from coils',
                texts: [
                    '<span class="t-base">Eco-insulator pad</span> prevents you from feeling the coils through the thinner 6” mattress',
                    'Adds durability to the mattress'
                ],
                imageIconPositionStandard: { left: '20%', top: '47%' },
                imageIconPositionTablet: { left: '24%', top: '49%' }
            },
            {
                title: 'Responsive coils',
                texts: [
                    '<span class="t-base">4” recycled steel coils encased in foam</span> for responsive support',
                    'Helps prevent sagging over time'
                ],
                imageIconPositionStandard: { left: '59%', top: '49%' },
                imageIconPositionTablet: { left: '59%', top: '50%' }
            },
            {
                title: 'Sturdy base layer',
                texts: [
                    '<span class="t-base">High-density support foam</span> helps anchor the mattress layers above',
                    'Highly resilient and durable for a longer-lasting mattress'
                ],
                imageIconPositionStandard: { left: '29%', top: '62%' },
                imageIconPositionTablet: { left: '33%', top: '64%' }
            }
        ]
    },
    [`${PRODUCT_CODES.MURPHY_BED_MATTRESS}`]: {
        pictureFolder: 'mattress-layers',
        pictureName: 'murphy-bed-mattress-diagram',
        imgAlt: 'Murphy Bed mattress diagram',
        title: `Space-saving comfort built into every layer`,
        selectedIconColor: 'bgContrast2',
        layers: [
            {
                title: 'Soft, breathable, water-resistant cover',
                texts: [
                    'Cover made with naturally breathable <span class="t-base">organic cotton</span> to promote cooler, healthier sleep',
                    'Proprietary blend of <span class="t-base">comfort foams</span> instantly add a cushioned feel',
                    '<span class="t-base">Hand-tufted</span> to prevent layers from shifting with movement and upright Murphy bed storage',
                    'No fiberglass or toxic chemical flame retardants, instead we use <span class="t-base">natural, plant-based thistle pulp flame barrier</span>'
                ],
                imageIconPositionStandard: { left: '18%', top: '8%' },
                imageIconPositionTablet: { left: '14%', top: '19%' }
            },
            {
                title: 'Body-contouring and pressure relief',
                texts: [
                    '<span class="t-base">1” 2.5 lb density memory foam comfort layer</span> provides body-contouring support and pressure relief',
                    '<span class="t-base">Gel-infused</span> to keep you cooler by actively drawing heat away '
                ],
                imageIconPositionStandard: { left: '27%', top: '17%' },
                imageIconPositionTablet: { left: '23%', top: '26%' }
            },
            {
                title: 'Transitional comfort layer',
                texts: [
                    '<span class="t-base">1.5 lb density poly foam</span> acts as a transition layer between the memory foam above and the supportive coil unit below',
                    'Provides additional comfort and support'
                ],
                imageIconPositionStandard: { left: '20%', top: '27%' },
                imageIconPositionTablet: { left: '15%', top: '34%' }
            },
            {
                title: 'Responsive, body-cradling comfort',
                texts: [
                    '<span class="t-base">8” individually wrapped coils</span> contour to your curves to relieve pressure points for better circulation',
                    '<span class="t-base">Patented Lumbar Zone® Active Spinal Wire</span> helps to keep your spine in healthy alignment',
                    'Provides a better support system than regular all-foam Murphy bed mattresses'
                ],
                imageIconPositionStandard: { left: '45%', top: '41%' },
                imageIconPositionTablet: { left: '42%', top: '45%' }
            },
            {
                title: 'No sagging edges',
                texts: [
                    '<span class="t-base">High-density foam rails</span> create stable, firmer edge support while helping to maintain mattress shape when stored upright',
                    'Gives you more sleep surface and makes getting in and out of bed easier'
                ],
                imageIconPositionStandard: { left: '32%', top: '46%' },
                imageIconPositionTablet: { left: '30%', top: '49%' }
            },
            {
                title: 'Stable & durable base',
                texts: [
                    '<span class="t-base">Recycled fiber base pad</span> insulates the bottom of the coil unit',
                    'Made from eco-friendly recycled poly-cotton',
                    'Provides a stable base to anchor the hand-tufting'
                ],
                imageIconPositionStandard: { left: '43%', top: '56%' },
                imageIconPositionTablet: { left: '44%', top: '57%' }
            }
        ]
    }
}

export {
    config
}
