import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './SupportValueProps.module.scss'

const InteractiveElement = ({ href, children, ...rest }) => {
    if (href) {
        return <a href={href} {...rest}>
            {children}
        </a>
    }
    return <button {...rest}>
        {children}
    </button>
}

const SupportValueProp = ({ text, subheading, component, action }) => {
    const colClasses = classNames('col col--xs-12 col--md-4', styles.valuePropColumn)
    const imageClasses = classNames(styles.image)
    const headingClasses = classNames('t-heading4', styles.contentHead)

    return (
        <div className={colClasses}>
            <InteractiveElement className={styles.valuePropCard} {...action}>
                <div className={imageClasses}>{component}</div>
                <h3 className={headingClasses}>{text}</h3>
                {subheading}
            </InteractiveElement>
        </div>
    )
}

SupportValueProp.propTypes = {
    text: PropTypes.string.isRequired,
    component: PropTypes.element.isRequired,
    subheading: PropTypes.node,
    action: PropTypes.shape({
        href: PropTypes.string,
        onClick: PropTypes.func
    })
}

export default SupportValueProp
