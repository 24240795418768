import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SupportValueProp from './SupportValueProp'
import SupportValuePropsConfig from './SupportValuePropsConfig'

import styles from './SupportValueProps.module.scss'

// This component must be added to each pdp contentConfig file to render.
const SupportValueProps = ({ configKey, backgroundColorClass }) => {
    const { title, items } = SupportValuePropsConfig(configKey)

    const sectionClasses = classNames('section', styles.supportSection, backgroundColorClass)
    const containerClasses = classNames('container')
    const headingClasses = classNames('t-heading2-refined', styles.supportSectionHeading)
    const rowClasses = classNames('row', styles.supportSectionRow)

    return (
        <section className={sectionClasses}>
            <div className={containerClasses} data-selector='support-value-props-section'>
                <h2 className={headingClasses}>{title}</h2>
                <div className={rowClasses}>
                    {items.map(({ text, subheading, component, action }, i) =>
                        <SupportValueProp
                            key={`supportValueProp-${i}`}
                            text={text}
                            subheading={subheading}
                            component={component}
                            action={action}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

SupportValueProps.propTypes = {
    configKey: PropTypes.oneOf(['standard', 'supportProps']),
    backgroundColorClass: PropTypes.string
}

export default React.memo(SupportValueProps)
